<template>
  <main>
    <div class="pb">
      <Button icon="md-return-left" shape="circle" @click="$router.back()">返回</Button>
    </div>

    <Form ref="form" :model="form" :rules="validate" label-position="top">
      <Divider orientation="left">基本信息</Divider>
      <Row>
        <Col :span="9" :offset="3">
          <FormItem label="渠道" prop="member_card_channel_id">
            <Select v-model="form.member_card_channel_id" class="w50">
              <Option :value="item.id" v-for="item in channels" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="生成数量" prop="create_num">
            <InputNumber :min="1" class="input-number-long" v-model="form.create_num"/>
          </FormItem>
          <FormItem label="有效天数" prop="valid_days">
            <InputNumber :min="0" class="input-number-long" v-model="form.valid_days"/>天
            <p class="text-sm text-grey">卡片激活后的有效时间</p>
          </FormItem>
          <FormItem label="渠道价" prop="unit_price_channel">
            <InputNumber :min="0" class="input-number-long" v-model="form.unit_price_channel"/>元
          </FormItem>
          <FormItem label="零售价" prop="unit_price_retail">
            <InputNumber :min="0" class="input-number-long" v-model="form.unit_price_retail"/>元
          </FormItem>
        </Col>
      </Row>

      <Divider/>
      <Row>
        <Col :span="9" :offset="3">
          <FormItem>
            <Button type="primary" icon="md-add-circle" @click="submit" v-if="!id">确认生成</Button>
            <Button type="success" icon="md-filing" @click="submit" v-else>保存修改</Button>
          </FormItem>
        </Col>
      </Row>
    </Form>
  </main>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      form: {
        member_card_channel_id: 0,
        create_num: 0,
        valid_days: 0,
        unit_price_channel: 0,
        unit_price_retail: 0
      },
      validate: {
        member_card_channel_id: [
          {
            required: true,
            type: "number",
            min:0,
            message: "请选择渠道",
            trigger: "blur"
          }
        ],
         create_num: [
          {
            required: true,
            type: "number",
            message: "请输入生成数量",
            trigger: "blur"
          }
        ],
        valid_days: [
          {
            required: true,
            type: "number",
            message: "请输入有效天数",
            trigger: "blur"
          }
        ],
        unit_price_channel: [
          {
            required: true,
            type: "number",
            message: "请输入渠道价",
            trigger: "blur"
          }
        ],
        unit_price_retail: [
          {
            required: true,
            type: "number",
            message: "请输入零售价",
            trigger: "blur"
          }
        ],
      },
      channels: []
    };
  },
  created() {
    this.loadChannels();
  },
  methods: {
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const url =
            "/memberCard/" +
            (this.id ? "update/id/" + this.id : "create");
          this.$http.post(url, this.form).then(res => {
            this.$router.back();
          });
        }
      });
    },
    loadData() {
      this.$http.get("/memberCard/detail/id/" + this.id).then(res => {
        this.form = res;
      });
    },
    loadChannels() {
      this.$http.get("/memberCardChannel/list").then(res => {
        this.channels = res.data;
        this.id && this.loadData();
      });
    },
  }
};
</script>